import React from "react";

const NotFoundPage = () => (
  <div>
    <h1>NOT FOUND</h1>
    <p>Doggggg</p>
  </div>
);

export default NotFoundPage;
